var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{staticClass:"shadow",staticStyle:{"width":"100%"},attrs:{"width":"100%","headers":_vm.ownerTableHeaders,"items":_vm.$props.items,"item-key":"id","items-per-page":10,"show-expand":true},scopedSlots:_vm._u([{key:"item._type",fn:function(ref){
var item = ref.item;
return [(item._type)?_c('div',[(item._type.toLowerCase().includes('juridisk'))?_c('div',[_vm._v(" 🏢 Juridisk ")]):_c('div',[_vm._v(" 🏠 Privat ")])]):_vm._e()]}},{key:"item.ownershipCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownerships.length)+" ")]}},{key:"item.postadresse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPostAddress(item))+" ")]}},{key:"item.exclusionReason",fn:function(ref){
var item = ref.item;
return [(!item.isHardExcluded)?_c('v-text-field',{model:{value:(item.exclusionReason),callback:function ($$v) {_vm.$set(item, "exclusionReason", $$v)},expression:"item.exclusionReason"}}):_c('div',[_vm._v(_vm._s(item.exclusionReason))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$props.type === 'included')?_c('v-btn',{attrs:{"icon":"","disabled":_vm.$props.disableinputs},on:{"click":function($event){return _vm.excludeOwner(item)}}},[_c('v-icon',[_vm._v("mdi-minus-circle-outline")])],1):_vm._e(),(_vm.$props.type === 'excluded')?_c('v-btn',{attrs:{"disabled":_vm.$props.disableinputs || item.isHardExcluded,"icon":""},on:{"click":function($event){return _vm.includeOwner(item);}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"1rem 1rem"},attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"text-algin":"left","justify-self":"start"}},[(item._type.toLowerCase().includes('juridisk'))?_c('span',[_vm._v("Organisasjonsnummer: "+_vm._s(item.nummer))]):_c('span',[_vm._v("Personnummer: "+_vm._s(item.nummer))])]),_c('h2',[_vm._v("Eierforhold")]),_c('VDataTable',{attrs:{"headers":_vm.ownershipHeaders,"items":item.ownerships,"item-key":"id","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.adresse",fn:function(ref){
var item = ref.item;
return [(item.eier && item.eier.postadresse)?_c('div',[(item.eier.postadresse.adresselinje1)?_c('div',[_vm._v(_vm._s(item.eier.postadresse.adresselinje1))]):_vm._e(),(item.eier.postadresse.adresselinje2)?_c('div',[_vm._v(_vm._s(item.eier.postadresse.adresselinje2))]):_vm._e(),(item.eier.postadresse.adresselinje3)?_c('div',[_vm._v(_vm._s(item.eier.postadresse.adresselinje3))]):_vm._e(),(item.eier.postadresse.adresselinje4)?_c('div',[_vm._v(_vm._s(item.eier.postadresse.adresselinje4))]):_vm._e(),(item.eier.postadresse.adresselinje5)?_c('div',[_vm._v(_vm._s(item.eier.postadresse.adresselinje5))]):_vm._e()]):_vm._e()]}},{key:"item.andel",fn:function(ref){
var item = ref.item;
return [(item.andel && item.andel.teller && item.andel.nevner)?_c('div',[_vm._v(" "+_vm._s(item.andel.teller)+" / "+_vm._s(item.andel.nevner)+" ("+_vm._s((item.andel.teller / item.andel.nevner) * 100)+"%) ")]):_vm._e()]}}],null,true)})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }